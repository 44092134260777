<template>
	<Template
			:items="resources"
			:pagination="{
				totalRecords: state.resource.totalRecords,
				perPage: state.resource.resourcePerPage,
				currentPage: state.resource.activePage
			}"
			@onClick="(resource) => $router.push({name: 'Resource Detail', params: {id: resource?.id}})"
	/>
</template>

<script>
import Template from '../components/templates/VentureResources/VentureResources'
export default {
	components: {
		Template
	},
	data() {
		return {
			resources: []
		}
	},
	async created() {
		await this.actions.resource.getResources();
		Object.values(this.state.resource.resources)?.map(r => {
			this.resources.push({
				id: r.id,
				title: r.name,
				imgSrc: r?.directLink?.source,
				desc: r?.body,
				items: [
					{
						icon: "LinkIcon",
						label: r.type
					},
					{
						label: "Getting Started"
					}
				],
				customClass: ""
			})
		})
		await this.actions.resource.getTotalResources();
	}
}
</script>

<style scoped>

</style>
